import React from "react"
import Title from "../Title"
import { IoIosCheckmarkCircle} from "react-icons/io"

const RecruitmentReasons = ({services}) => {
  return (
    <section id={'recruitment-services'} className={"section bg-grey"}>
      <Title title={"WHAT WE DO"} />
      <div className="section-center srecruitment-service-heading">
        <h3 className={'recruitment-service-center-heading'}>We <span className='underline--animated'><strong>find any talent</strong></span> on the market including space tech.</h3>
        {services.map(service => {
          const { id, icon, title, text } = service
          return (
            <div key={id} className={"recruitment-service"}>
              <span className="recruitment-service-icon-wrapper"><IoIosCheckmarkCircle className="service-icon"/></span>
              <h4>{title}</h4>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default RecruitmentReasons
