import React from "react"
import Title from "./Title"
import Form from "./Form"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
  }
`

const Contact = ({isSubTitle, formName}) => {
  const {site} = useStaticQuery(query);
  const {email, phone} = site.siteMetadata;
  return (
    <section id={'contact'} className={"section bg-grey"}>
      <Title title={"Get in touch"} />
      <div className="section-center contact-center">
        {
          isSubTitle &&
          <h4 className={'contact-center-heading'}>We specialize in creating high performing websites and apps. Whether you need to set up your online strategy from scratch, drastically improve your website or app or give your team an innovative kick, we have you covered.<br /> Fill out this form and we will contact you.</h4>
        }
        <div className="contact-form-section">
          <Form name={formName}/>
          <div className={'contact-form-footer'}>
            <h5>More ways to reach us:</h5>
            <p>Email: <a href={`mailto:${email || 'info@techspace.ae'}`} className={'link'}>{email || 'info@techspace.ae'}</a></p>
            <p>Phone: <a href={`tel:${phone || '+971585029594'}`} className={'link'}>{phone || '+971585029594'}</a></p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
