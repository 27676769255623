import React from "react"
import { MdGroup } from "react-icons/md"
import { FaFileAlt } from "react-icons/fa"
import { RiTimerFill, RiCopyrightFill } from "react-icons/ri"
export default [
  {
    id: 1,
    icon: <RiTimerFill className="service-icon" />,
    title: "We appreciate your time",
    text: `At the very start, we identify requirements and collect all of the information about your company, vacancy and your perfect candidate, so later we won’t bother you.`,
  },
  {
    id: 2,
    icon: <RiCopyrightFill className="service-icon" />,
    title: "We promote your brand",
    text: `While negotiating with candidates, we can be silent about your brand and discuss only requirements and conditions with them. Or we can tell everyone how brilliant you are. You decide ;)`,
  },
  {
    id: 3,
    icon: <MdGroup className="service-icon" />,
    title: "We support deversity",
    text: `Want a versatile team? We understand the importance of diversity in the company, so we ready to help you with it. `,
  },
  {
    id: 4,
    icon: <FaFileAlt className="service-icon" />,
    title: "We give you all the information",
    text: `It’s no surprise, we put our back into recruiting. If you want to see the process, how many candidates we’ve contacted – we will send you a report with all information, that you desire.`,
  },
 
]
