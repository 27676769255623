import React from "react"
import Title from "../Title"
import { IoIosCheckmarkCircle} from "react-icons/io"

const RecruitmentProcess = ({process}) => {
  return (
    <section id={'recruitment-process'} className={"section"}>
      <Title title={"How we work"} />
      <div className="section-center recruitment-process-section">
        {process.map((service, number) => {
          const { id, Description, Title, text } = service
          return (
            <div key={id} className={"recruitment-process-item"}>
              <h4>{Title}</h4>
              <div className={"recruitment-process-item--sub"}>
                {Description}
              </div>
              <span className="recruitment-process-number">{number}</span>
              {/* SVG for path. Need to update and adapt */}
              {/* <svg width="605" height="300" viewBox="0 0 580 300" class="hidden xl:block xl:absolute" style="left: 3rem; bottom: -12rem;" preserveAspectRatio="none"><path d="M.44,1.52v142c0,24.14,11.79,34.69,34.69,34.69,282,0,504.68-.34,504.68-.34,26.08,1,35,8.51,35,28v44.21" style="fill: none;stroke: #1b1c40;stroke-linecap: round;stroke-width: 2px;stroke-dasharray: 0,10"></path></svg> */}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default RecruitmentProcess
