import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import RecruitmentReasons from "../components/recruitment/Reasons"
import RecruitmentProcess from "../components/recruitment/Process"
import Contact from "../components/Contact"
import RecruitmentBenefits from "../components/recruitment/Benefits"

const Recruitment = ({ data: { recruitment: { nodes }, }, location}, ) => {
  const { Title, SubTitle,  Process, Services, team } = nodes[0];

  return <Layout>
    <SEO
     title={'Recruitment Agency in Dubai'}
     description={'Start your employee search with one of the fastest groving recruitment agencyin in Dubai & Middle East. We will help you to find the best website developer, app developer, data scientist, marketing specialist, designer and many other employees.'}
     pathname={location.pathname}
     image={'/recruitment-img.png'}
    />
    <Hero title={Title} subTitle={SubTitle}/>
    <RecruitmentReasons services={Services}/>
    <RecruitmentProcess process={Process}/>
    <RecruitmentBenefits/>
    <Contact formName="Recruitment"/>
  </Layout>
}


export const query = graphql`
  {
    recruitment:allStrapiRecruitment {
      nodes {
        SubTitle
        Title
        Team {
          Name
          position
          photo {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          id
        }
        Services {
          title
          id
        }
        Process {
          Title
          Description
          id
        }
      }
    }
  }
`

export default Recruitment
