import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import SocialLinks from "../constants/socialLinks"
import rocket from "../assets/rocket.svg"
import backfire from "../assets/backfire.svg"
import stars from "../assets/stars.svg"

const query = graphql`
  {
    file(relativePath: { eq: "hero-img.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Hero = ({isMainPage, title, subTitle}) => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <header data-qs="sticky-header-anchor" className={"hero"}>
      <div className={`section-center hero-center `}>
        <article className={`hero-info ${!isMainPage ? 'hero-info--center' : ''}`}>
          {
            isMainPage &&
            <div className="hero-info--prefix">
              (Re)
            </div>
          }
          <div>
            <h1>{title ? title :  'Launch your online business'}</h1>
            {subTitle ? <h4 className='hero-sub-title'>{subTitle}</h4> : ''}
          </div>
        </article>
        <div className={`hero-planet ${!isMainPage ? 'hero-planet--center' : ''}`}>
          <div>
            <img src={rocket}  className={'hero-rocket'} alt="Rocket" />
            <img src={backfire} className={'hero-backfire'} alt="Backfire" />
            <img src={stars} className={'hero-stars'} alt="Stars" />
          </div>
        </div>
      </div>
      <a href={!isMainPage ? '#recruitment-services' :  '#services'} className={'hero-scroll'}>
        <div>
          Scroll
        </div>
        <div className='hero-scroll-icons'>
          <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.11328 6.80078C6.32812 7.01562 6.62891 7.01562 6.84375 6.80078L11.8281 1.77344C12.043 1.55859 12.043 1.21484 11.8281 1.04297L11.5273 0.742188C11.3125 0.527344 11.0117 0.527344 10.7969 0.742188L6.5 5.125L2.16016 0.699219C1.94531 0.527344 1.64453 0.527344 1.42969 0.699219L1.12891 1.04297C0.914062 1.21484 0.914062 1.55859 1.12891 1.77344L6.11328 6.80078Z" fill="#78869A"/>
          </svg>
          <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.11328 6.80078C6.32812 7.01562 6.62891 7.01562 6.84375 6.80078L11.8281 1.77344C12.043 1.55859 12.043 1.21484 11.8281 1.04297L11.5273 0.742188C11.3125 0.527344 11.0117 0.527344 10.7969 0.742188L6.5 5.125L2.16016 0.699219C1.94531 0.527344 1.64453 0.527344 1.42969 0.699219L1.12891 1.04297C0.914062 1.21484 0.914062 1.55859 1.12891 1.77344L6.11328 6.80078Z" fill="#78869A"/>
          </svg>
        </div>
      </a>
    </header>
  )
}

export default Hero
